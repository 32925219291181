export const CTA_TEXT_BANNER_HEIGHT = "100px";
export const HEADING_FONT_FAMILY = "OpenSans-Bold, Arial, sans-serif";

//-----------TEXT-----------//
export const HERO_DESCRIPTION_TEXT =
  "Plus de Thrillers, de Science-Fiction, de Glamour et de Fun avec Universal+ !";
export const CTA_BUTTON_TEXT = "ESSAI GRATUIT";
export const CTA_BANNER_BUTTON_TEXT = "Abonnez-vous";
export const CTA_BANNER_TEXT = `
Sur Universal+, accédez à plusieurs milliers d'heures de séries à la
demande ainsi qu’aux chaînes 13ÈME\u00A0RUE, SYFY, E! et DreamWorks !
`;
export const CTA_BANNER_MOBILE_TEXT =
  "Accédez à des milliers d'heures de séries à la demande sur Universal+.";
export const CTA_LEGAL_TEXT =
  "Pour les abonnés Prime Video. Abonnement requis, voir conditions.";

export const CTA_BUTTON_LINK =
  "https://www.primevideo.com/offers?benefitId=universalplusfr";
export const FOOTER_LINK_TITLE = " Liens utiles";
export const HEADER_TEXT = "Où regarder Universal+";

//-----------IDS-----------//
export const CTA_BUTTON_ID = "cta-button";
export const BANNER_BUTTON_ID = "banner-button";
export const AFFILIATE_PRIMEVIDEO_BUTTON = "affiliate-primevideo-button";
export const AFFILIATE_SFR_BUTTON = "affiliate-sfr-button";
export const AFFILIATE_BOUYGUES_BUTTON = "affiliate-bouygues-button";
export const AFFILIATE_MOLOTOV_BUTTON = "affiliate-molotov-button";
export const AFFILIATE_FREE_BUTTON = "affiliate-free-button";
export const SOCIALMEDIA_FACEBOOK_BUTTON = "socialmedia-facebook-button";
export const SOCIALMEDIA_YOUTUBE_BUTTON = "socialmedia-youtube-button";
export const SOCIALMEDIA_INSTAGRAM_BUTTON = "socialmedia-instagram-button";
export const SOCIALMEDIA_TIKTOK_BUTTON = "socialmedia-tiktok-button";
export const HERO_SECTION_ID = "hero-section";
export const COLLECTION_SECTION = "collection-section";
export const ORIGINAL_PRODUCTIONS_SECTION_ID = "original-productions-section";
export const SHOWCASE_SECTION = "showcase-section";
export const SOCIAL_SECTION = "social-section";
export const MONTHLY_SECTION_ID = "monthly-section";
export const FAQ_SECTION = "faq-section";
export const FOOTER_SECTION = "footer-section";
export const HERO_VIDEO_BUTTON = "hero-video-button";
export const SHOWCASE_VIDEO_BUTTON = "showcase-video-button";
export const SCROLL_13EME_BUTTON = "scroll-13eme-channel-button";
export const SCROLL_SYFY_BUTTON = "scroll-syfy-channel-button";
export const SCROLL_E_BUTTON = "scroll-e-channel-button";
export const SCROLL_DREAMWORKS_BUTTON = "scroll-dreamworks-channel-button";
