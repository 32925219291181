import { lazy, memo, useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import heroImage from "../../../../assets/images/hero/16x9.webp";
import heroMobileImage from "../../../../assets/images/hero/4x5.webp";
import { HERO_SECTION_ID } from "../../../../constants";

const VideoBanner = lazy(() => import("./VideoBanner/VideoBanner"));
const HeroBanner = lazy(() => import("./HeroBanner/HeroBanner"));
const HeroBannerMini = lazy(() => import("./HeroBanner/HeroBannerMini"));

const MemoizedVideoBanner = memo(VideoBanner);
const MemoizedHeroBanner = memo(HeroBanner);
const MemoizedHeroBannerMini = memo(HeroBannerMini);

const MosaicSection = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [showVideo, setShowVideo] = useState(false);

  const video = "/videos/hero/16x9/output.mpd";
  const videoMobile = "/videos/hero/4x5/output.mpd";

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVideo(true);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Box
      component={"section"}
      id={HERO_SECTION_ID}
      sx={{
        height: "100vh",
        width: "100%",
        position: "relative",
        mb: matchDownMd ? "32px" : "0",
        bgcolor: theme.palette.primary.main,
      }}
    >
      {!showVideo && !matchDownMd && (
        <MemoizedHeroBanner showVideo={showVideo} imgSrc={heroImage} />
      )}

      {!showVideo && matchDownMd && (
        <MemoizedHeroBannerMini
          showVideo={showVideo}
          imgSrc={heroMobileImage}
        />
      )}

      {showVideo && (
        <MemoizedVideoBanner
          showVideo={showVideo}
          videoSrc={matchDownMd ? videoMobile : video}
          setShowVideo={setShowVideo}
        />
      )}
    </Box>
  );
};

export default MosaicSection;
